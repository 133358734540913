import '../scss/front.scss';

global.$ = global.jQuery = $;

import FormNoticia from './components/form-noticia';
global.FormNoticia = FormNoticia;

import Degradado from './front/degradado';
global.Degradado = Degradado;

import 'select2';
require('./components/ckeditor');

import 'bootstrap';
import './front/buscador';
import './front/destacada';
import './front/leer-mas';
import './front/menu';
import './front/portada';
import './front/scroll-infinito';
import './front/sticky';
import './front/ancla';
import './front/galeria';
import './front/slick';
import './front/twitter';