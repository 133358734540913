import IniciarGaleria from './galeria';
import iniciarTwitter from './twitter';

const pixelOffset = 500;
const porcentajeVisible = 75;
const windowHeight = window.innerHeight;
var $scrollContainer = $("#scroll-wrapper");
var loading = false;
var last = false;

if (!last && $scrollContainer.length) { 
    window.addEventListener('scroll', function () {
        if (!loading && (window.innerHeight + window.scrollY >= document.body.offsetHeight - pixelOffset)) {
            loading = true;

            $scrollContainer.append('<hr class="hr-scroll">');
            addSpinner($scrollContainer);
            
            $.ajax({
                url : '/scroll',
                type : "GET",
                success : function(data) {
                    if (data == 'OK') { 
                        last = true;
                    } else {    
                        $scrollContainer.append(data);
                        loading = false;    
                    }
                    iniciarTwitter();
                    removeSpinner();
                    pararVideos();
                }
            });  
        }

        var el = getArticuloVisible();
        mostrarFaldon(el.dataset.publicidad);
        actualizaSEO(el.dataset.title, el.dataset.path);
        actualizaShare(el.dataset.title, el.dataset.path, el.dataset.description);
        IniciarGaleria(el.dataset.id);
    });
} else {
    window.addEventListener('scroll', function () {
        const articles = document.querySelectorAll('article.noticia');
        if ( articles.length > 0 ) {
            IniciarGaleria( articles[0].dataset.id );
        }
    });
}

// Actualiza title y URL del navegador y enlaces a redes sociales
function actualizaSEO(title, url) {
    // seo
    window.history.replaceState({}, title, url);
    document.title = title;
}

function actualizaShare(title, url, description) {
    var share = $('.rrss-share.rrss-share-mobile');
    if (share) {
        var linkedin = $('.rrss-share .share-linkedin');
        var twitter = $('.rrss-share .share-twitter');
        var mail = $('.rrss-share .share-mail');
        var whatsapp = $('.rrss-share .share-whatsapp');
        linkedin.attr('href', 'http://www.linkedin.com/shareArticle?mini=1&url=https%3A//www.reasonwhy.es'+url+'&title='+title+'&source=https%3A//www.reasonwhy.es'+url);
        twitter.attr('href', 'https://twitter.com/intent/tweet/?via=ReasonWhy&text='+title+'&url=https://www.reasonwhy.es'+url);
        mail.attr('href', 'mailto:?subject=Te%20comparto%20esta%20noticia%20de%20Reason%20Why&body='+title+'%0A'+description+'%0Ahttps://www.reasonwhy.es'+url+'%0A%0ASuscríbete%20a%20la%20Newsletter%20de%20Reason%20Why:%20https://rwhy.es/3cd9ZwH');
        whatsapp.attr('href', 'https://api.whatsapp.com/send?text=https://www.reasonwhy.es'+url);
    }
}

// Devuelve el artículo que carga la información de la página
function getArticuloVisible() {
    var articulo = null;
    var porcentaje = 0;
    var articles = document.querySelectorAll('article.noticia');

    articles.forEach(el => {
        // var por = getScreenPorcentaje(el);
        var por = getPosPorcentaje(el);

        if (!articulo) {
            articulo = el;
            porcentaje = por;
        }

        if (por < porcentajeVisible && por > porcentaje) {
        // if (por > porcentaje) {
            articulo = el;
            porcentaje = por;
        }
    });

    return articulo;
}

// Calcula el porcentaje de alto que ocupa el elemento en la pantalla
function getScreenPorcentaje(el) {
    var elHeight = el.offsetHeight;
    var posTop = el.getBoundingClientRect().top;
    var posBottom = windowHeight - (posTop + elHeight);

    if (posTop < 0 && posBottom < 0) {
        return 100;
    } else if (posTop > 0 && posBottom > 0) {
        var alto = posTop - posBottom;
        return (alto * 100) / windowHeight;
    } else if (posTop < 0 && posBottom > 0)  {
        var alto = windowHeight - posBottom;
        return (alto * 100) / windowHeight;
    } else if (posTop > 0 && posBottom < 0) {
        var alto = windowHeight - posTop;
        return (alto * 100) / windowHeight;
    }
}

// Devuelve la posición relativa del titular del elemento respecto a la pantalla
function getPosPorcentaje(el) {
    var title = el.getElementsByTagName('h1')[0];
    var posTop = title.getBoundingClientRect().top;

    return (posTop * 100) / windowHeight;
}

function addSpinner(parent) {
    parent.append('<div id="spinner-scroll"></div>');
}

function removeSpinner() {
    $('#spinner-scroll').remove();
}
// parar videos al cargar nueva noticia
function pararVideos() { 
  
    var videos = document.getElementsByTagName("iframe");
    if (videos) {
        for (var i = 0; i < videos.length; i++) {
            var src = videos[i].getAttribute("src");
            if(src.includes("www.youtube")) {
                videos[i].src = videos[i].src;
            }
        }
    }   
}

// faldon mombramientos
function mostrarFaldon(etiqueta) {
    var faldon = $('.publi-noticia-FOOTERSTICKYINTERIORCONTEXTUAL');
    var stickyInterior = $('.publi-noticia-FOOTERSTICKYINTERIOR');
    var faldonMobile = $('.publi-noticia-FALDONINTERIORCONTEXTUAL');
    var stickyInteriorMobile = $('.publi-noticia-FALDONINTERIOR');
    var larguero = $('.publi-noticia-LARGUEROINTERIOR');
    if ( etiqueta == 'publicidad-etiqueta'){
        if (faldon) {
            faldon.removeClass("oculto");
        }
        if (stickyInterior) {
            stickyInterior.removeClass("visible");
        }
        if (faldonMobile) {
            faldonMobile.removeClass("oculto");
        }
        if (stickyInteriorMobile) {
            stickyInteriorMobile.removeClass("visible");
        }
        if (larguero) {
            larguero.removeClass("visible");
        }
    }else{
        if (faldon) {
            faldon.addClass("oculto");
        }
        if (stickyInterior) {
            stickyInterior.addClass("visible");
        }
        if (faldonMobile) {
            faldonMobile.addClass("oculto");
        }
        if (stickyInteriorMobile) {
            stickyInteriorMobile.addClass("visible");
        }
        if (larguero) {
            larguero.addClass("visible");
        }
    }
}